import { domain, clientId, audience, apiUri } from '../../auth_config.json';

// export const environment = {
//   production: true,
//   version:'3.0.5',
//   mapStyleUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
//   mixpanel_token : '5017fb4d1032395dacf774f41d228b61',
//   api_url:'https://preprodarbre.urbasense.eu/Southside_api/v7.1.2/',
//   sujet_photo_url :'https://preprodarbre.urbasense.eu',
//   key_roles: 'http://urbasense/',
//   auth: {
//     "domain": "devurbasense.eu.auth0.com",
//     "clientId": "CsPvfYyz33SnRUv7VC9wTQyPl93pYBim",
//     "audience": "https://api.urbasense.dev",
//     redirectUri: window.location.origin,
//   },
//   httpInterceptor: {
//     allowedList: ['https://preprodarbre.urbasense.eu/Southside_api/v7.1.2/private/*'],
//   },
// };


export const environment = {
  production: true,
  enableDebug: false,
  version:'4.0.0',
  mapStyleUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  mixpanel_token : '5017fb4d1032395dacf774f41d228b61',
  api_url:'https://arbre.urbasense.eu/Southside_api/v7.2.0/',
  sujet_photo_url :'https://arbre.urbasense.eu/',
  key_roles: 'http://urbasense/',
  auth: {
    "domain": "urbasense2k.eu.auth0.com",
    "clientId": "37BlrJ8K9OoTOhtVUoBlLYj6RThJ6nvp",
    "audience": "https://api-webapp-urbasense.com",
    "CacheLocation": "localstorage",
    redirectUri: window.location.origin,
  },
  httpInterceptor: {
    allowedList: ['https://arbre.urbasense.eu/Southside_api/v7.2.0/private/*'],
  },
};


//37BlrJ8K9OoTOhtVUoBlLYj6RThJ6nvp prod
//4g22oqa2kSJ8G7QgPF8LzkGPy3p742Rs dev




